import React from 'react';
import { Grid, Container, Paper, Typography, Avatar, Button } from '@mui/material';
import { auth } from '../firebase';
import LeftPanel from '../components/Panels/LeftPanel/LeftPanel';
import RightPanel from '../components/Panels/RightPannel/RightPanel';
import { ExitToApp } from '@mui/icons-material';
import AdCarousel from '../components/Ads/AdCarousel/AdCarousel';
import './MainPage.css';

const MainPage = ({ user, isAdmin }) => (
  <div className="main-page-container">
    <Grid container>
      <Grid item xs={12} md={2} className="panel-container-left">
        <LeftPanel isAdmin={isAdmin} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Container maxWidth="md" className="welcome-container">
          <Paper elevation={3} className="welcome-paper">
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Avatar src={user.photoURL} alt={user.displayName || user.email} />
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  Welcome, {user.displayName || user.email}
                </Typography>
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<ExitToApp />}
              onClick={() => auth.signOut()}
              style={{ marginTop: '20px' }}
            >
              Sign Out
            </Button>
          </Paper>
        </Container>
      </Grid>
      <Grid item xs={12} md={2} className="panel-container">
        <RightPanel />
      </Grid>
    </Grid>
    <AdCarousel />
  </div>
);

export default MainPage;
