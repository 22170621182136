import React, { useState, useEffect } from "react";
import { db } from "../../firebase"; // Adjust the path as needed
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import "./ProductList.css";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [form, setForm] = useState({ name: "", details: "", gst: "" });
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
    };

    fetchProducts();
  }, []);

  const handleOpen = (product) => {
    setCurrentProduct(product);
    setForm(
      product
        ? { name: product.name, details: product.details, gst: product.gst }
        : { name: "", details: "", gst: "" }
    );
    setOpen(true);
    setError("");
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentProduct(null);
    setForm({ name: "", details: "", gst: "" });
    setError("");
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!form.name || !form.details || !form.gst) {
      setError("Please fill in all fields.");
      return;
    }

    try {
      if (currentProduct) {
        await updateDoc(doc(db, "products", currentProduct.id), form);
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === currentProduct.id ? { ...product, ...form } : product
          )
        );
      } else {
        const docRef = await addDoc(collection(db, "products"), form);
        setProducts((prevProducts) => [
          ...prevProducts,
          { id: docRef.id, ...form },
        ]);
      }
      handleClose();
    } catch (error) {
      setError("Error submitting the form. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "products", id));
      setProducts((prevProducts) => prevProducts.filter((product) => product.id !== id));
    } catch (error) {
      setError("Error deleting the product. Please try again.");
    }
  };

  const paginatedProducts = products.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < products.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="product-list-container">
      <div className="product-head-sub">
        <h6>Product List</h6>
        <button className="add-button" onClick={() => handleOpen(null)}>
          <span>+</span>
        </button>
      </div>
      <ul className="product-list">
        {paginatedProducts.map((product) => (
          <li key={product.id}>
            <span>Name: {product.name}</span>
            <span>Details: {product.details}</span>
            <span>GST: {product.gst}</span>
            <div className="actions">
              <button
                className="edit-button"
                onClick={() => handleOpen(product)}
              >
                Edit
              </button>
              <button
                className="delete-button"
                onClick={() => handleDelete(product.id)}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="pagination-buttons">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className="prev"
        >
          <span>&larr;</span>
        </button>
        <button
          onClick={handleNextPage}
          disabled={(currentPage + 1) * itemsPerPage >= products.length}
          className="next"
        >
          <span>&rarr;</span>
        </button>
      </div>
      {open && (
        <div className="modal">
          <div className="modal-content">
            <h2>{currentProduct ? "Edit Product" : "Add Product"}</h2>
            <input
              type="text"
              name="name"
              placeholder="Product Name"
              value={form.name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="details"
              placeholder="Product Details"
              value={form.details}
              onChange={handleChange}
            />
            <input
              type="text"
              name="gst"
              placeholder="GST"
              value={form.gst}
              onChange={handleChange}
            />
            {error && <p className="error-message">{error}</p>}
            <div className="modal-actions">
              <button onClick={handleClose}>Cancel</button>
              <button onClick={handleSubmit}>
                {currentProduct ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductList;
