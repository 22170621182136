import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, sendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './AuthPage.css';

const AuthPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [productKey, setProductKey] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSignUp = async () => {
    try {
      const productKeyDoc = await getDoc(doc(db, 'productKeys', productKey));
      if (!productKeyDoc.exists()) {
        setError('Invalid product key');
        return;
      }

      const productKeyData = productKeyDoc.data();
      const expirationDate = new Date(productKeyData.expirationDate);
      const currentDate = new Date();

      if (currentDate > expirationDate) {
        setError('Expired product key');
        return;
      }

      if (productKeyData.usedBy) {
        setError('Product key has already been used');
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(userCredential.user, { displayName: username });

      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email,
        productKeyValid: true,
        productKeyExpirationDate: expirationDate.toISOString(),
      });

      await setDoc(doc(db, 'productKeys', productKey), {
        ...productKeyData,
        usedBy: userCredential.user.uid,
        usedDate: currentDate.toISOString()
      });

      setError(null);
    } catch (error) {
      console.error("Error signing up: ", error);
      setError(error.message);
    }
  };

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError(null);
    } catch (error) {
      console.error("Error signing in: ", error);
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    } 
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Check your inbox.');
      setError(null);
    } catch (error) {
      console.error("Error resetting password: ", error);
      setError(error.message);
    }
  };

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
    setError(null);
    setMessage(null);
  };

  return (
    <div className="auth-container">
      <h1>{isSignUp ? "Sign Up" : "Sign In"}</h1>
      {isSignUp && (
        <>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="auth-input"
          />
          <input
            type="text"
            value={productKey}
            onChange={(e) => setProductKey(e.target.value)}
            placeholder="Product Key"
            className="auth-input"
          />
        </>
      )}
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        className="auth-input"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        className="auth-input"
      />
      <button onClick={isSignUp ? handleSignUp : handleSignIn} className="auth-button">
        {isSignUp ? "Sign Up" : "Sign In"}
      </button>
      {!isSignUp && (
        <button onClick={handleForgotPassword} className="auth-link">
          Forgot Password?
        </button>
      )}
      <button onClick={toggleSignUp} className="auth-toggle">
        {isSignUp ? "Already have an account? Sign In" : "Don't have an account? Sign Up"}
      </button>
      {error && <p className="auth-error">{error}</p>}
      {message && <p className="auth-message">{message}</p>}
    </div>
  );
};

export default AuthPage;
