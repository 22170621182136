import React, { useState, useEffect } from 'react';
import { updateProfile } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './UserProfile.css';
import CelebrationPopup from '../Celebration/CelebrationPopup';

const UserProfile = ({ user, onUpdateUsername }) => {
  const [newUsername, setNewUsername] = useState('');
  const [email, setEmail] = useState(user.email || ''); // Fetch email directly from user object
  const [productKey, setProductKey] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [daysUntilExpiry, setDaysUntilExpiry] = useState(null);
  const [showCelebration, setShowCelebration] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState('');
  const [editingField, setEditingField] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log('User Data:', userData);

          setEmail(userData.email || user.email || ''); // Set email
          setProductKey(userData.productKey || '');
          setPhoneNumber(userData.phoneNumber || '');

          if (userData.productKey) {
            const productKeyDoc = await getDoc(doc(db, 'productKeys', userData.productKey));
            if (productKeyDoc.exists()) {
              const productKeyData = productKeyDoc.data();
              const expirationDate = new Date(productKeyData.expirationDate);
              setExpiryDate(expirationDate);

              const currentDate = new Date();
              const timeDiff = expirationDate - currentDate;
              const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
              setDaysUntilExpiry(daysDiff);

              if (daysDiff <= 0) {
                await auth.signOut();
                navigate('/auth');
                alert('Your product key has expired. Please renew to continue using the app.');
              }
            } else {
              console.error('No product key document found');
            }
          } else {
            console.error('No product key found for the user');
          }
        } else {
          console.error('No user document found');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [user, navigate]);

  const handleFieldUpdate = async (field, value) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { [field]: value });
      setCelebrationMessage(`${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully!`);
      setShowCelebration(true);
      setEditingField('');
      setTimeout(() => {
        setShowCelebration(false);
        navigate(0); // Reload the same page to reflect updated data
      }, 2000);
    } catch (error) {
      console.error(`Error updating ${field}: `, error);
    }
  };

  const handleUsernameUpdate = async (e) => {
    e.preventDefault();
    if (!newUsername) return;
    try {
      await updateProfile(auth.currentUser, { displayName: newUsername });
      onUpdateUsername(newUsername);
      setNewUsername('');
      setCelebrationMessage('Username updated successfully!');
      setShowCelebration(true);
      setTimeout(() => {
        setShowCelebration(false);
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Error updating username: ', error);
    }
  };

  const handleEdit = (field) => {
    setEditingField(field);
  };

  const renderEditForm = () => {
    switch (editingField) {
      case 'email':
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFieldUpdate('email', email);
            }}
            className="profile-form"
          >
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              className="profile-input"
            />
            <button type="submit" className="profile-button-update">Update</button>
          </form>
        );
      case 'productKey':
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFieldUpdate('productKey', productKey);
            }}
            className="profile-form"
          >
            <input
              type="text"
              value={productKey}
              onChange={(e) => setProductKey(e.target.value)}
              placeholder="Enter product key"
              className="profile-input"
            />
            <button type="submit" className="profile-button-update">Update</button>
          </form>
        );
      case 'phoneNumber':
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFieldUpdate('phoneNumber', phoneNumber);
            }}
            className="profile-form"
          >
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter phone number"
              className="profile-input"
            />
            <button type="submit" className="profile-button-update">Update</button>
          </form>
        );
      case 'username':
        return (
          <form
            onSubmit={handleUsernameUpdate}
            className="profile-form"
          >
            <input
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              placeholder="Enter new username"
              className="profile-input"
            />
            <button type="submit" className="profile-button-update">Update</button>
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div className="user-profile-container">
      <h2>User Profile</h2>
      <div className="user-details">
        <div className="profile-field">
          {editingField === 'email' ? (
            renderEditForm()
          ) : (
            <>
              <p>Email: <strong>{email}</strong></p>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit('email')} />
            </>
          )}
        </div>
        <div className="profile-field">
          {editingField === 'productKey' ? (
            renderEditForm()
          ) : (
            <>
              <p>Product Key: <strong>{productKey}</strong></p>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit('productKey')} />
            </>
          )}
        </div>
        <div className="profile-field">
          {editingField === 'phoneNumber' ? (
            renderEditForm()
          ) : (
            <>
              <p>Phone Number: <strong>{phoneNumber}</strong></p>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit('phoneNumber')} />
            </>
          )}
        </div>
        <div className="profile-field">
          {editingField === 'username' ? (
            renderEditForm()
          ) : (
            <>
              <p>Username: <strong>{user.displayName || 'No username'}</strong></p>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit('username')} />
            </>
          )}
        </div>
        {expiryDate && (
          <div className="profile-field">
            <p>Expiry Date: <strong>{expiryDate.toDateString()}</strong></p>
          </div>
        )}
      </div>

      {daysUntilExpiry !== null && (
        <div className="expiry-info">
          {daysUntilExpiry > 0 ? (
            <p>Your product key will expire in <strong>{daysUntilExpiry}</strong> {daysUntilExpiry === 1 ? 'day' : 'days'}.</p>
          ) : (
            <p>Your product key has expired. Please renew to continue using the app.</p>
          )}
        </div>
      )}
      <button className="back-home-button" onClick={() => navigate('/')}>Back to Home</button>
      {showCelebration && (
        <CelebrationPopup
          message={celebrationMessage}
          onClose={() => setShowCelebration(false)}
        />
      )}
    </div>
  );
};

export default UserProfile;
