import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { auth } from './firebase';
import AuthPage from './pages/AuthPage';
import Orders from './components/Orders/Orders';
import Tasks from './components/Task/Tasks';
import Finance from './components/Finance/Finance';
import Header from './components/Header/Header';
import UserProfile from './components/User_Profile/UserProfile';
import ProductKeyManager from './components/ProductKeyManager/ProductKeyManager';
import useCheckProductKey from './components/ProductKey/useCheckProductKey';
import MainPage from './pages/MainPage';
import Footer from './components/Footer/Footer';
import './App.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user && user.email === 'admin@gmail.com') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    });
    return unsubscribe;
  }, []);

  useCheckProductKey();

  // Determine if the current route is the auth page
  const isAuthPage = location.pathname === '/auth';

  return (
    <div className="app-container">
      {!isAuthPage && <Header user={user} />}
      <main className="app-main">
        <Routes>
          <Route path="/auth" element={user ? <Navigate to="/" /> : <AuthPage />} />
          <Route path="/orders" element={user ? <Orders /> : <Navigate to="/auth" />} />
          <Route path="/tasks" element={user ? <Tasks userId={user.uid} /> : <Navigate to="/auth" />} />
          <Route path="/userprofile" element={user ? <UserProfile user={user} onUpdateUsername={(name) => setUser({ ...user, displayName: name })} /> : <Navigate to="/auth" />} />
          <Route path="/finance" element={user ? <Finance /> : <Navigate to="/auth" />} />
          <Route path="/productkeys" element={isAdmin ? <ProductKeyManager /> : <Navigate to="/" />} />
          <Route path="/" element={user ? <MainPage user={user} isAdmin={isAdmin} /> : <Navigate to="/auth" />} />
        </Routes>
      </main>
      {!isAuthPage && <Footer />}
    </div>
  );
};

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
